// styles
import './showPdfDialog.css';
// mui components
import { Dialog } from '@mui/material';


export default function ShowPDFDialog({ pdfFile, fileName, openPDF, setOpenPDF }){

    const handleClosePdfDialog = ()=>{
        setOpenPDF(false);
    }

    return(
        <Dialog open={openPDF}
                onClose={handleClosePdfDialog}
                maxWidth={'lg'}
                fullWidth={true}
        >
            {/* <DialogContent> */}
                <iframe
                    src={pdfFile}
                    title={fileName}
                    width="100%"
                    height="600px"
                    style={{ marginTop: '20px', border: '1px solid #ccc' }}
                />
            {/* </DialogContent> */}
        </Dialog>
    )
}