// styles
import './navbar.css';
// react scroll
import { Link } from 'react-scroll';

export default function Navbar(){
    return(
        <nav className='portfolio-navbar'>
            <Link to="homepage" smooth={true} duration={500} className="nav-link">
                Home
            </Link>
            <Link to="education" smooth={true} duration={500} className="nav-link">
                Education
            </Link>
            <Link to="skills" smooth={true} duration={500} className="nav-link">
                Skills
            </Link>
            <Link to="projects" smooth={true} duration={500} className="nav-link">
                Projects
            </Link>
            <Link to="contact_me" smooth={true} duration={500} className="nav-link">
                Contact me
            </Link>
            {/* <a href="/education">Education</a>
            <a href="/skills">Skills</a>
            <a href="/projects">Projects</a>
            <a href="/contact_me">Contact</a> */}
        </nav>
    )
}