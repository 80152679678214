// styles
import './fullView.css';
// Pages
import Education from '../Education/education';
import Homepage from '../Homepage/homepage';
import Skills from '../Skills/skills';
import Projects from '../Projects/projects';
import ContactMe from '../Contact/contactMe';

export default function FullView(){
    return(
        <div className="full-view-page">
            <div id="homepage">
                <Homepage/>
            </div>
            <div id="education">
                <Education/>
            </div>
            <div id="skills">
                <Skills/>
            </div>
            <div id="projects">
                <Projects/>
            </div>
            <div id="contact_me">
                <ContactMe/>
            </div>
        </div>
    )
}