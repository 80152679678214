// react states
import { useState } from 'react';
// database
import { db } from '../../Firebase/firebase.js';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

export const useSendMessage = ()=>{
    const [sending, setSending] = useState(false);
    const [sendStatus, setSendStatus] = useState(null);
    const [sendError, setSendError] = useState(null);

    const sendMessage = async(firstName, lastName, email, message)=>{
        // Default states
        setSendError(null);
        setSendStatus(null);
        setSending(true);

        // Main operation of sending the message.
        const messageRef = collection(db, 'Messages');
        await addDoc(messageRef, {
            'firstName': firstName,
            'lastName': lastName,
            'email': email,
            'message':message,
            'createdAt': serverTimestamp()
        }).catch((err)=>{
            // Catching error if any.
            setSendError(err.message);
            setSendStatus('fail');
            setSending(false);
            return;
        });

        // Here, because no error occured and the message was sent successfully.
        setSending(false);
        setSendError(null);
        setSendStatus('success');

    }

    return { sending, sendStatus, sendError, sendMessage };
}