// styles
import './education.css';
// pictures/logos
import uofg_logo from '../Assets/Educations/UoG_keyline.png';
import muj_logo from '../Assets/Educations/muj.png';
import jaipuria_logo from '../Assets/Educations/jaipuria.png';
// pdf files
import btechDegree from '../Assets/Educations/EducationDocs/btech_degree.pdf';
import markSheet from '../Assets/Educations/EducationDocs/class_10th_and_12th_marksheets.pdf';
// mui components
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import ShowPDFDialog from '../Components/EducationComponents/showPdfDialog';

export default function Education(){

    const [showDegreePdf, setShowDegreePdf] = useState(false);

    const [showSchoolMarksheets, setShowSchoolMarksheets] = useState(false);
    

    // To show the degree 
    const handleOpenDegreePdf = ()=>{
        setShowDegreePdf(true);
    }

    // To show school transcripts
    const handleOpenSchoolMarksheets = ()=>{
        setShowSchoolMarksheets(true);
    }

    return(
        <div className="education-page-container">
            { 
                showDegreePdf &&
                <ShowPDFDialog
                    pdfFile={btechDegree}
                    openPDF={showDegreePdf}
                    setOpenPDF={setShowDegreePdf}
                    fileName={'My Degree'}
                />
            }
            {
                showSchoolMarksheets &&
                <ShowPDFDialog
                    pdfFile={markSheet}
                    openPDF={showSchoolMarksheets}
                    setOpenPDF={setShowSchoolMarksheets}
                    fileName={'ICSE [ GCSE/O-LEVEL ] & ISC[A-LEVEL] marksheet'}
                />
            }
            <p className='education-main-heading'>Education</p>
            <div className="uofg-container">
                <div className="uofg-logo-container">
                    <img src={uofg_logo} alt="University of Glasgow" />
                </div>
                <div className="uofg-qualifications">
                    <p className='education-heading'>University of Glasgow</p>
                    <p className='education-subheading'>Glasgow, Scotland</p>
                    <p className='education-subheading'>MSc in Computer Systems Engineering</p>
                    <p className='education-texts'>Subjects: Software Engineering, Secured Software Engineering, Big Data</p>
                    <p className='education-texts'>Major Project : i-Rheo Web</p>
                </div>
            </div>
            <div className="muj-container">
                <div className="muj-logo-container">
                    <img src={muj_logo} alt="Manipal University Jaipur" />
                </div>
                <div className="muj-qualifications">
                    <p className='education-heading'>Manipal University Jaipur</p>
                    <p className='education-subheading'>Jaipur, India</p>
                    <p className='education-subheading'>Bachelor of Technology in Information Technology</p>
                    <p className='education-texts'>Subjects: Software Engineering and Project Management, JAVA Programming, Python Programming, Relational Database and Management Systems, Data Algorithms and Analysis,
                        Data Structure and Algorithms
                    </p>
                    <p className='education-texts'>Major Project : Traveller app</p>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={()=>handleOpenDegreePdf()}
                    >
                        Show degree
                    </Button>
                </div>
            </div>
            <div className="muj-container">
                <div className="muj-logo-container">
                    <img src={jaipuria_logo} alt="Seth M.R. Jaipuria School" />
                </div>
                <div className="muj-qualifications">
                    <p className='education-heading'>Seth M.R. Jaipuria School</p>
                    <p className='education-subheading'>Lucknow, India</p>
                    <p className='education-subheading'>ICSE[IGCSE/O-LEVEL], ISC[A-LEVEL]</p>
                    <p className='education-texts'>
                        ICSE[IGCSE/O-LEVEL] : 98% in Computer Science
                    </p>
                    <p className='education-texts'>
                        ISC[A-LEVEL] : 95% in Computer Science
                    </p>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={()=>handleOpenSchoolMarksheets()}
                    >
                        Show transcripts
                    </Button>
                </div>
            </div>
        </div>
    )
}