// styles
import './App.css';
// react router dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Navbar
import Navbar from './Navbar/navbar.jsx';
// Pages 
import Homepage from './Homepage/homepage.jsx';
import Education from './Education/education.jsx';
import FullView from './FullView/fullView.jsx';
import Skills from './Skills/skills.jsx';
import Projects from './Projects/projects.jsx';
import ContactMe from './Contact/contactMe.jsx';
// MUI components
import { createTheme, ThemeProvider } from '@mui/material/styles'; 

// Creating theme so that it is consistent throughout the application
const theme = createTheme({
  components: {
    MuiButton:{
      styleOverrides:{
        root:{
          backgroundColor: '#FFC86B',
          borderRadius: '25px',
          color:'#34353A',
          '&:hover':{
            backgroundColor:'#e6b85d'
          }
        }
      }
    }
  }
});



function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' exact element={<FullView/>}/>
          <Route path='/about' element={<Homepage/>}/>
          <Route path='/education' element={<Education/>}/>
          <Route path='/skills' element={<Skills/>}/>
          <Route path='/projects' element={<Projects/>} />
          <Route path='/contact_me' element={<ContactMe/>} />
        </Routes>
      </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
