// styles
import './contactMe.css';
// React states
import { useEffect, useRef, useState } from 'react';
// mui components
import { Alert, Button, CircularProgress, Collapse } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// Custom hook(s)
import { useSendMessage } from '../Hooks/ContactMe/useSendMessage';

export default function ContactMe(){
    
    // Ref to access textarea 
    const messageRef = useRef(null);

    // custom hook to send the message to the database.
    const { sending, sendStatus, sendError, sendMessage } = useSendMessage();

    // Form inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Setting default configuration of the input fields.
    const setInputFieldsDefaultConfig = ()=>{
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    }

    // Form input errors
    // const [fnError, setFnError] = useState(false);
    // const [lnError, setLnError] = useState(false);
    // const [emailError, setEmailError] = useState(false);
    // const [messageError, setMessageError] = useState(false);


    // To automatically resize the text area
    useEffect(()=>{
        autoResizeMessageArea();
    },[message])
    const autoResizeMessageArea = ()=>{
        const messageArea = messageRef.current;
        if(messageArea){
            messageArea.style.height = 'auto';
            messageArea.style.height = `${messageArea.scrollHeight}px`;
        }
    }

    // Submission handling
    // To handle to alert messages.
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const triggerAlert = (message, severity = 'warning') => {
        setAlertMessage(message);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 5000); // Auto-close after 5 seconds
    };
    // To handle success message.
    const successTriggerAlert = ()=>{
        setAlertMessage("Message was sent successfully. I'll get in touch as soon as possible.");
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 5000);
    }

    // Default configuration of error messages.
    // const setErrorMessageDefaultConfig = ()=>{
    //     setFnError(false);
    //     setLnError(false);
    //     setEmailError(false);
    //     setMessageError(false);
    // }

    const handleSubmission = ()=>{
        // Set the error messages to default configuration.
        // setErrorMessageDefaultConfig();
        // First handle the error cases if the input fields are empty.
        if(firstName === '' || firstName.length === 0){
            // setFnError(true);
            triggerAlert('First name is required.');
            return;
        }
        if(email === '' || email.length === 0){
            // setEmailError(true);
            triggerAlert('Email is required.');
            return;
        }
        if(message === '' || message.length === 0){
            // setMessageError(true);
            triggerAlert('Message field is required.');
            return;
        }

        // Handle the error case where the email id is not valid.
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(email) === false){
            // setEmailError(true);
            triggerAlert('Please enter a valid Email address');
            return;
        }

        sendMessage(firstName, lastName, email, message);
    }

    // Show the success alert when the send status is success
    useEffect(()=>{
        if(sendStatus === 'success'){
            successTriggerAlert();
            setInputFieldsDefaultConfig();
        }else if(sendStatus === 'fail'){
            triggerAlert('Oops! Something went wrong. Please try again in a few seconds...')
        }else if(sendStatus === null){
            return;
        }
    },[sendStatus])


    return(
        <div className="contact-me-container">
            {/* To give feedback for wrong inputs. */}
            <Collapse in={showAlert} className="contact-alert">
                <Alert severity="warning" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
                {
                    sendStatus === 'success' &&
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert> 
                }
                {   sendStatus === 'fail' && sendError &&
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                }
            </Collapse>
            <p className='contact-heading'>Contact Me</p>
            <div className="contact-form-container">
                <div className="contact-input-container">
                    <input className='contact-input'
                           type="text" 
                           placeholder='First Name'
                           value={firstName}
                           onChange={(e)=>setFirstName(e.target.value)}
                    />
                    <input className='contact-input'
                           type="text" 
                           placeholder='Last Name'
                           value={lastName}
                           onChange={(e)=>setLastName(e.target.value)}
                    />
                </div>
                <div className="contact-input-container">
                    <input className='contact-input'
                            type="email" 
                            placeholder='Email'
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                    />
                </div>
                <div className="contact-input-container">
                    <textarea className='contact-text-area'
                            ref={messageRef}
                            type="text" 
                            rows={7}
                            placeholder='Write your message here...'
                            value={message}
                            onChange={(e)=>setMessage(e.target.value)}
                    />
                </div>
                <div className="contact-action-container">
                    <Button disabled={sending === true ? true : false} 
                            endIcon={ sending === true ? <CircularProgress size={18}/> : <SendIcon/>}
                            onClick={()=>handleSubmission()}
                    >
                        Send message
                    </Button>
                </div>
            </div>
        </div>
    )
}