// styles
import './homepage.css';
// react scrolls
import { Link } from 'react-scroll';
// assets
import me from '../Assets/Me/ev-s-dlAp3YS6HigsZyLO-transformed.png';
import gfg from '../Assets/Socials/gfg-new-logo.png';
import so from '../Assets/Socials/logo-stackoverflow.png';
import linkedin from '../Assets/Socials/LI-In-Bug.png';
import insta from '../Assets/Socials/Instagram_Glyph_Gradient.png';
import github_logo from '../Assets/Skills/github-mark-white.png';
// mui components
import { Button } from '@mui/material';

export default function Homepage(){

    // Functions to open my socials
    const openGfgProfile = ()=>{
        window.open('https://www.geeksforgeeks.org/user/khansabih8/','_blank');
    }

    const openStackOverflowProfile = ()=>{
        window.open('https://stackoverflow.com/users/10006062/sabih-khan','_blank');
    }

    const openGithubProfile = ()=>{
        window.open('https://github.com/khansabih','_blank');
    }

    const openLinkedInProfile = ()=>{
        window.open('https://www.linkedin.com/in/sabih-khan-1824021a3/','_blank');
    }

    const openInstaProfile = ()=>{
        window.open('https://www.instagram.com/khansabih/','_blank');
    }

    return(
        <div className="homepage-container">
            <div className="homepage-banner-area">
                <div className="middle-div">
                    <div className="portfolio-image-container">
                        <img src={me} alt="Sabih Khan" />
                    </div>
                </div>
                <div className="homepage-banner-texts">
                    <div className="left-side">
                        <p className='left-side-heading'>SABIH</p>
                        <p className='left-side-normal-txt'>I am a Frontend Web Developer</p>
                    </div>
                    <div className="empty-middle-side">
                        <p>&nbsp;</p>
                        <div className="socials-container">
                            <div className="social-div"
                                 onClick={()=>openGfgProfile()}
                            >
                                <img src={gfg} alt='GeekForGeeks' />
                            </div>
                            <div className="social-div"
                                 onClick={()=>openStackOverflowProfile()}
                            >
                                <img src={so} alt='StackOverflow' />
                            </div>
                            <div className="social-div"
                                 onClick={()=>openGithubProfile()}
                            >
                                <img src={github_logo} alt="Github"/>
                            </div>
                            <div className="social-div"
                                 onClick={()=>openLinkedInProfile()}
                            >
                                <img src={linkedin} alt='LinkedIn' />
                            </div>
                            <div className="social-div"
                                 onClick={()=>openInstaProfile()}
                            >
                                <img src={insta} alt='Instagram' />
                            </div>
                        </div>
                        <Link to="contact_me" smooth={true} duration={500} className="homepage-nav-link">
                            <Button variant='contained'>
                                Contact me
                            </Button>
                        </Link>         
                    </div>
                    <div className="right-side">
                        <p className='right-side-heading'>KHAN</p>
                        <p className='right-side-normal-txt'>Welcome to my world of programming</p>
                    </div>
                </div>
            </div>
        </div>
    )
}