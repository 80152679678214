// styles
import './projects.css';
// projects icons
import tgb from '../Assets/Projects/the-gamer-bros.png';
import irheo from '../Assets/Projects/irheo.png';
import real_estate from '../Assets/Projects/real-estate.png';
import my_pic from '../Assets/Me/sabih.png';
// MUI icons
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Projects(){

    const tgb_about = "The Gamer Broz is a community-driven gaming platform where users can write and share reviews of their favorite games. The site lets gamers to contribute their own insights, fostering a vibrant community of players who can discover new games and discuss their experiences. With an easy-to-use interface, The Gamer Broz is designed to make sharing opinions simple, helping players connect with others who share their passion for gaming.";
    const irheo_about = "i-Rheo Web is a specialized platform focused on rheology, offering tools and resources to analyze and visualize complex rheological data. It provides a user-friendly interface designed for researchers and professionals in the field, enabling efficient data processing and interpretation. The site emphasizes accuracy and ease of use, making it an essential tool for those involved in rheology research and applications. With its advanced features, i-Rheo Web stands out as a reliable resource for comprehensive rheological analysis.";
    const repp_about = "This project focused on predicting real estate prices in Bangalore using machine learning. The repository includes a Jupyter Notebook with code for data preprocessing, model training, and evaluation, alongside a pre-trained model saved in a pickle file. The project leverages regression techniques to predict property prices based on various features, such as location, size, and amenities. It's a practical example of applying data science to solve real-world problems in the real estate domain.";

    // To take the user to the gamer bros
    const openGamerBros = ()=>{
        window.open('https://thegamerbroz.com','_blank');
    }

    // To take the user to the repository of i-rheo web
    const openIRheoWebRepositories = ()=>{
        window.open('https://github.com/khansabih/iRheo-frontend','_blank');
        window.open('https://github.com/khansabih/iRheo-backend','_blank');
    }

    // To take user to the real estate price prediction repository
    const openREPP = ()=>{
        window.open('https://github.com/khansabih/Real-estate-price-prediction','_blank');
    }

    return(
        <div className="projects-list-container">
            <p className='project-list-heading'>
                My Projects
            </p>
            {/* The Gamer Bros */}
            <div className="project-tile">
                <div className="project-icon-container">
                    <img src={tgb} alt='The Gamer Bros'/>
                </div>
                <div className="project-details">
                    <p className='project-tile-heading'>The Gamer Bros</p>
                    <p className='project-tile-about'>{tgb_about}</p>
                    <div className='project-stack-text'><p className='project-tile-key'>Framework(s): </p><p className='project-tile-about'>Reactjs</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Database: </p><p className='project-tile-about'>Firebase( FirebaseAuth, Cloud Firestore & Firebase Storage )</p></div>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={()=>openGamerBros()}
                    >
                        View Deployed Site
                    </Button>
                </div>
            </div>

            {/* I-RHEO */}
            <div className="project-tile">
                <div className="project-icon-container">
                    <img src={irheo} alt='i-rheo'/>
                </div>
                <div className="project-details">
                    <p className='project-tile-heading'>i-Rheo Web</p>
                    <p className='project-tile-about'>{irheo_about}</p>
                    <div className='project-stack-text'><p className='project-tile-key'>Framework(s): </p><p className='project-tile-about'>Reactjs</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Backend: </p><p className='project-tile-about'>Django</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Database: </p><p className='project-tile-about'>Firebase( FirebaseAuth only for login purpose )</p></div>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={()=>openIRheoWebRepositories()}
                    >
                        View Github Repository
                    </Button>
                    <p className='project-tile-key'>*The project is under development and will be deployed soon.</p>
                </div>
            </div>

            {/* REAL ESTATE PRICE PREDICTION */}
            <div className="project-tile">
                <div className="project-icon-container">
                    <img src={real_estate} alt='Real Estate Price Prediction'/>
                </div>
                <div className="project-details">
                    <p className='project-tile-heading'>Real Estate Price Prediction</p>
                    <p className='project-tile-about'>{repp_about}</p>
                    <div className='project-stack-text'><p className='project-tile-key'>Programming Language(s): </p><p className='project-tile-about'>Python</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Concepts: </p><p className='project-tile-about'>Data preprocessing, Linear Regression, Decision Tree</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Libraries: </p><p className='project-tile-about'>Pandas, Numpy, Scikit, Matplotlib</p></div>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={()=>openREPP()}
                    >
                        View Github Repository
                    </Button>
                </div>
            </div>

            <div className="project-tile">
                <div className="project-icon-container">
                    <img src={my_pic} alt='Sabih Ayaz Khan'/>
                </div>
                <div className="project-details">
                    <p className='project-tile-heading'>This Portfolio</p>
                    <p className='project-tile-about'>This web app was also made to serve as a testament to my ability to create interactive and fully functional web apps.</p>
                    <div className='project-stack-text'><p className='project-tile-key'>Framework(s): </p><p className='project-tile-about'>Reactjs</p></div>
                    <div className='project-stack-text'><p className='project-tile-key'>Database: </p><p className='project-tile-about'>Firebase (Cloud firestore)</p></div>
                </div>
            </div>
        </div>
    )
}