// styles
import './skills.css';
// skillset logos
import reactjs from '../Assets/Skills/reactjs.png';
import html from '../Assets/Skills/html.png';
import css_logo from '../Assets/Skills/css.png';
import js_logo from '../Assets/Skills/JavaScript-logo.png';
import flutter from '../Assets/Skills/Flutter/icon_flutter/icon_flutter.png'
import java_logo from '../Assets/Skills/java.png';
import python_logo from '../Assets/Skills/python.png';
import firebase_logo from '../Assets/Skills/Firebase Logo & Product Icons  2/Firebase Logo/Primary Logomark/PNG/Logomark_Full Color.png';
import mysql_logo from '../Assets/Skills/mysql.png';
import github_logo from '../Assets/Skills/github-mark-white.png';
import bitbucket_logo from '../Assets/Skills/Bitbucket/PNG@2x/mark-gradient-blue-bitbucket.png';

export default function Skills(){
    return(
        <div className="skills-container">
            <p className='skills-heading'>My Skills</p>
                <div className="frameworks-div">
                    
                {/* Web development Frameworks */}
                <div className="skills-subheading">Web Development</div>
                    <div className="skills-area">
                        <div className="skill-tile">
                            <img src={reactjs} alt="React.js"/>
                            <p>Reactjs</p>
                        </div>
                        <div className="skill-tile">
                            <img src={html} alt="HTML"/>
                            <p>HTML</p>
                        </div>
                        <div className="skill-tile">
                            <img src={css_logo} alt="CSS"/>
                            <p>CSS</p>
                        </div>
                        <div className="skill-tile">
                            <img src={js_logo} alt="Javasript"/>
                            <p>Javascript</p>
                        </div>
                    </div>
                </div>

                {/* Mobile Application Development */}
                <div className="frameworks-div">
                    <div className="skills-subheading">Mobile Application Development</div>
                    <div className="skills-area">
                        <div className="skill-tile">
                            <img src={flutter} alt="Flutter"/>
                            <p>Flutter</p>
                        </div>
                    </div>
                </div>

                {/* Standalone programming languages */}
                <div className="frameworks-div">
                    <div className="skills-subheading">Standalone Application Development</div>
                    <div className="skills-area">
                        <div className="skill-tile">
                            <img src={java_logo} alt="JAVA"/>
                            <p>JAVA</p>
                        </div>
                        <div className="skill-tile">
                            <img src={python_logo} alt="Python"/>
                            <p>Python</p>
                        </div>
                    </div>
                </div>

                {/* Databases */}
                <div className="frameworks-div">
                    <div className="skills-subheading">Databases</div>
                    <div className="skills-area">
                        <div className="skill-tile">
                            <img src={firebase_logo} alt="Firebase"/>
                            <p>Firebase</p>
                        </div>
                        <div className="skill-tile">
                            <img src={mysql_logo} alt="MySql"/>
                            <p>MySql</p>
                        </div>
                    </div>
                </div>

                {/* Version Control */}
                <div className="frameworks-div">
                    <div className="skills-subheading">Version Control</div>
                    <div className="skills-area">
                        <div className="skill-tile">
                            <img src={github_logo} alt="Github"/>
                            <p>Github</p>
                        </div>
                        <div className="skill-tile">
                            <img src={bitbucket_logo} alt="Bitbucket"/>
                            <p>Bitbucket</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}