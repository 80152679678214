// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxfkuk9_j-omgXy2-oOmpWVJzIP3cXV7o",
  authDomain: "my-portfolio-e85df.firebaseapp.com",
  projectId: "my-portfolio-e85df",
  storageBucket: "my-portfolio-e85df.appspot.com",
  messagingSenderId: "174622853307",
  appId: "1:174622853307:web:4b634623232a046048019e",
  measurementId: "G-E8QX97FGSR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, app, analytics };